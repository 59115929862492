import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import AdminDashboard from "./pages/admin/Dashboard";
import UserDashboard from "./pages/user/Dashboard";
import DesignerDashboard from "./pages/designer/Dashboard";
import DashboardLayout from "./components/dashboardpanel/common/DashboardLayout";
import Contactus from "./pages/Contactus";
import About from "./pages/About";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ExploreDesign from "./components/LandingPage/home/ExploreDesign";
import ExploreDesignsCard from "./pages/ExploreDesignsCard";
import NotFound from "./pages/NotFound";
import SearchTalent from "./pages/SearchTalent";
import UserProfileSettings from "./pages/user/ProfileSettings";
import UserSavedProjects from "./pages/user/SavedProjects";
import UserSecuritySettings from "./pages/user/SecuritySettings";
import DesignerProfileSettings from "./pages/designer/ProfileSettings";
import DesignerManagePortfolios from "./pages/designer/ManagePortfolios";
import DesignerSecuritySettings from "./pages/designer/SecuritySettings";
import ClientManagement from "./pages/admin/ClientManagement";
import DesignerCategories from "./pages/admin/DesignerCategories";
import DesignersManagement from "./pages/admin/DesignersManagement";
import PortfolioManagement from "./pages/admin/PortfolioManagement";
import FeaturedPortfolios from "./pages/admin/FeaturedPortfolios";
import DesignerProfiles from "./pages/DesignerProfiles";
import Terms from "./pages/Terms";
import ForgetPassword from "./pages/ForgetPassword";
import AdminSecuritySettings from "./pages/admin/SecuritySettings";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLogin from "./pages/admin/AdminLoginScreen";
import "react-toastify/dist/ReactToastify.css";
import RequireAuth from "./utils/checks/RequireAuth";
import Verify from "./pages/Verify";
import AddPortfolio from "./pages/designer/AddPortfolio";
import EditPortfolio from "./pages/designer/EditPortfolio";
import DesignerSkills from "./pages/admin/DesignerSkills";
import DesignerServices from "./pages/admin/DesignerServices";
import ClientDetails from "./pages/admin/ClientDetails";
import DesignerDetails from "./pages/admin/DesignerDetails";
import PortfolioDetails from "./pages/admin/PortfolioDetails";
import TokenCheck from "./utils/checks/TokenCheck";
import Queries from "./pages/admin/Queries";

function App() {
  useEffect(() => {
    import("bootstrap/dist/js/bootstrap.bundle");
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/user">
          <Route path="" element={<RequireAuth />}>
            <Route path="" element={<DashboardLayout />}>
              <Route index element={<Navigate to="/user/dashboard" />} />
              <Route path="dashboard" element={<UserDashboard />} />
              <Route path="profile" element={<UserProfileSettings />} />
              <Route path="saved-portfolio" element={<UserSavedProjects />} />
              <Route path="security" element={<UserSecuritySettings />} />
            </Route>
          </Route>
        </Route>
        <Route path="/designer">
          <Route path="" element={<RequireAuth />}>
            <Route path="" element={<DashboardLayout />}>
              <Route index element={<Navigate to="/designer/dashboard" />} />
              <Route path="dashboard" element={<DesignerDashboard />} />
              <Route path="profile" element={<DesignerProfileSettings />} />
              <Route path="portfolio">
                <Route path="" element={<DesignerManagePortfolios />} />
                <Route path="add" element={<AddPortfolio />} />
                <Route path="edit/:id" element={<EditPortfolio />} />
              </Route>
              <Route path="security" element={<DesignerSecuritySettings />} />
            </Route>
          </Route>
        </Route>
        <Route path="/admin">
          <Route path="login" element={<AdminLogin />} />
          <Route path="" element={<RequireAuth />}>
            <Route path="" element={<DashboardLayout />}>
              <Route index element={<Navigate to="/admin/dashboard" />} />
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="users" element={<ClientManagement />} />
              <Route path="categories" element={<DesignerCategories />} />
              <Route path="designers" element={<DesignersManagement />} />
              <Route path="portfolio" element={<PortfolioManagement />} />
              <Route
                path="featured-portfolio"
                element={<FeaturedPortfolios />}
              />
              <Route path="security" element={<AdminSecuritySettings />} />
              <Route path="skills" element={<DesignerSkills />} />
              <Route path="services" element={<DesignerServices />} />
              <Route path="queries" element={<Queries />} />
              <Route path="client-details" element={<ClientDetails />} />
              <Route path="designer-details" element={<DesignerDetails />} />
              <Route path="portfolio-details" element={<PortfolioDetails />} />
            </Route>
          </Route>
        </Route>
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/aboutus" element={<About />} />

        <Route path="" element={<TokenCheck />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgetpassword" element={<ForgetPassword />} />
        </Route>

        <Route path="/explore-design" element={<ExploreDesign />} />
        <Route path="/project/:id" element={<ExploreDesignsCard />} />
        <Route path="/designer-profile/:id" element={<DesignerProfiles />} />
        <Route path="/designers" element={<SearchTalent />} />
        <Route path="/privacy-policy" element={<Terms />} />

        <Route path="verify-email/:token" element={<Verify />} />

        <Route path="/*" element={<NotFound />} />
      </Routes>
      <ToastContainer closeOnClick />
    </Router>
  );
}

export default App;
