import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { RiAddFill, RiCloseFill } from "react-icons/ri";
import {
  singleImageUpload,
  updateDesignerData,
  updatePortfolio,
} from "../../api/Api";
import Loader from "./Loader";
import { setDesigner } from "../../redux/actions/designerActions";
import { useParams } from "react-router-dom";

function ImageGallery({
  title,
  clientData,
  setClientData,
  token,
  mode,
  ...props
}) {
  const dispatch = useDispatch();

  const inputRef = useRef();
  const [imageGallery, setImageGallery] = useState([]);
  const [loading, setLoading] = useState();
  const [deleteLoading, setDeleteLoading] = useState();

  const { id } = useParams();

  useEffect(() => {
    setImageGallery(clientData.images || []);
  }, [clientData]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (imageGallery.length >= 5) {
      toast.error(`You can upload max ${5} Pictures`);
      return;
    }

    if (file) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("image", file);

      const result = await singleImageUpload(formdata);
      if (result.status === "success") {
        const imageUrl = result.data;

        if (mode === "portfolio-add") {
          setImageGallery([...imageGallery, imageUrl]);
          setClientData((oldClientData) => ({
            ...oldClientData,
            images: [...imageGallery, imageUrl],
          }));
        } else {
          updateClientProfile([...imageGallery, imageUrl]);
        }
      } else {
        toast.error("Fail To Upload Image");
      }
    }
    setLoading(false);
    e.target.value = "";
  };

  const updateClientProfile = async (updatedImageGallery) => {
    switch (mode) {
      // DESIGNER
      case "designer":
        {
          const result = await updateDesignerData(
            { images: updatedImageGallery },
            token
          );

          if (result.status === "success") {
            toast.success("Media Gallery Updated Successfully");
            dispatch(setDesigner(result.data));
            setImageGallery(updatedImageGallery);
            setClientData((oldClientData) => ({
              ...oldClientData,
              images: updatedImageGallery,
            }));
          } else {
            toast.error(result.message);
          }
        }
        break;

      // PORTFOLIO
      case "portfolio-edit":
        {
          const result = await updatePortfolio(
            {
              ...clientData,
              images: updatedImageGallery,
              portfolioId: id,
            },
            token
          );

          if (result.status === "success") {
            toast.success("Media Gallery Updated Successfully");
            setImageGallery(updatedImageGallery);
            setClientData((oldClientData) => ({
              ...oldClientData,
              images: updatedImageGallery,
            }));
          } else {
            toast.error(result.message);
          }
        }
        break;

      // DEFUALT
      default:
        return null;
    }

    setDeleteLoading("");
  };

  const handleDeleteImage = async (index) => {
    const updatedImageGallery = imageGallery.filter((_, i) => i !== index);
    setDeleteLoading(imageGallery[index]);

    if (mode === "portfolio-add") {
      setImageGallery(updatedImageGallery);
      setClientData((oldClientData) => ({
        ...oldClientData,
        images: updatedImageGallery,
      }));
    } else {
      updateClientProfile(updatedImageGallery);
    }
  };

  return (
    <div className={`flex-grow-1 mb-3 ${props.className}`}>
      <div className="mb-3">
        <label htmlFor="images" className="form-label">
          {title} <small>(png, jpg, webp, Less than 2Mb)</small>
        </label>
        <input
          type="file"
          id="images"
          className="form-control fs-14"
          name="images"
          accept="image/*"
          onChange={handleFileChange}
          disabled={loading}
          ref={inputRef}
        />
      </div>
      <div className="d-flex gap-3 flex-wrap">
        {imageGallery.map((image, index) => (
          <div
            key={index}
            className="mt-3 p-2 rounded-3"
            style={{ backgroundColor: "#fbf7f4" }}
          >
            <div className="position-relative flex-grow-1 avatar-xl">
              <div className="overflow-hidden rounded-3 h-100">
                <img
                  src={image}
                  alt={`Gallery ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <button
                  type="button"
                  className="btn btn-light d-flex align-items-center p-0 position-absolute"
                  onClick={() => handleDeleteImage(index)}
                  style={{
                    aspectRatio: "1 / 1",
                    top: 5,
                    right: 5,
                  }}
                  disabled={deleteLoading}
                >
                  {deleteLoading === image ? (
                    <Loader size={12} color="black" />
                  ) : (
                    <RiCloseFill size={14} />
                  )}
                </button>
              </div>
            </div>
          </div>
        ))}
        <div
          className="mt-3 p-2 rounded-3"
          style={{ backgroundColor: "#fbf7f4" }}
        >
          <div className="avatar-xl d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn btn-light d-flex align-items-center p-0 rounded-pill"
              onClick={() => {
                inputRef.current.click();
              }}
              style={{
                background: "none",
                aspectRatio: "1 / 1",
              }}
              disabled={loading}
            >
              <RiAddFill size={32} color="#826f2b" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageGallery;
