import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  singleImageUpload,
  updateDesignerData,
  updateUserData,
} from "../../api/Api";
import { setUser } from "../../redux/actions/userActions";
import { setDesigner } from "../../redux/actions/designerActions";
import { RiCameraFill, RiCloseFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import Loader from "../../components/common/Loader";

export const showModal = (modalId) => {
  const modal = new window.bootstrap.Modal(document.getElementById(modalId));
  modal.show();
};

export const closeModal = (modalId) => {
  const modal = document.getElementById(modalId);
  const modalInstance = window.bootstrap.Modal.getInstance(modal);
  modalInstance.hide();
};

function ProfileAvatar({ data, setData, token }) {
  const [loading, setLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    data.profileImg || "/assets/images/avatar.png"
  );
  const [crop, setCrop] = useState({ aspect: 1, width: 200, height: 200 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [srcImage, setSrcImage] = useState(null);
  const imgRef = useRef(null);
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const variant = pathname.match(/^\/([^/]+)/)[1];

  useEffect(() => {
    setProfilePicture(data.profileImg || "/assets/images/avatar.png");
  }, [data]);

  const MIN_CROP_SIZE = { width: 80, height: 80 };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSrcImage(reader.result);
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const width = Math.max(img.width, MIN_CROP_SIZE.width);
          const height = Math.max(img.height, MIN_CROP_SIZE.height);

          setCrop({ aspect: 1, width: width, height: height });
        };
        showModal("profile-avatar-crop-modal");
      };
      reader.readAsDataURL(file);
    }
  };
  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  };

  const handleCropComplete = (crop) => {
    const adjustedCrop = {
      ...crop,
      width: Math.max(crop.width, MIN_CROP_SIZE.width),
      height: Math.max(crop.height, MIN_CROP_SIZE.height),
    };

    setCompletedCrop(adjustedCrop);
  };
  const handleCropConfirm = async () => {
    if (imgRef.current && completedCrop) {
      const croppedImageBlob = await getCroppedImg(
        imgRef.current,
        completedCrop
      );
      const croppedImageFile = new File(
        [croppedImageBlob],
        "cropped_image.jpg",
        { type: "image/jpeg" }
      );

      setLoading(true);
      const formdata = new FormData();
      formdata.append("image", croppedImageFile);

      const result = await singleImageUpload(formdata);
      if (result.status === "success") {
        await updateProfile(result.data);
      } else {
        toast.error("Failed to upload image");
      }

      setLoading(false);
      closeModal("profile-avatar-crop-modal");
    }
  };

  const updateProfile = async (imageUrl) => {
    const updateFunction =
      variant === "user" ? updateUserData : updateDesignerData;
    const setStateAction = variant === "user" ? setUser : setDesigner;

    const result = await updateFunction({ profileImg: imageUrl }, token);

    if (result.status === "success") {
      toast.success("Profile Picture Updated Successfully");
      dispatch(setStateAction(result.data));
      setProfilePicture(result.data.profileImg);
      setData((oldData) => ({
        ...oldData,
        profileImg: result.data.profileImg,
      }));
    } else {
      toast.error(result.message);
    }
  };

  return (
    <>
      <div className="profile-user position-relative d-inline-block mx-auto mb-4 avatar-xl">
        <img
          src={profilePicture}
          className="rounded-circle avatar-xl img-thumbnail user-profile-image"
          alt="user-profile"
        />
        <input
          id="profileImg"
          type="file"
          name="profileImg"
          className="profile-img-file-input"
          onChange={handleFileChange}
          disabled={loading}
        />
        <label
          htmlFor="profileImg"
          className="designer-avatar-settings avatar-xs"
        >
          <span className="avatar-title rounded-circle bg-light text-body">
            <RiCameraFill />
          </span>
        </label>
      </div>

      <div
        className="modal fade"
        id="profile-avatar-crop-modal"
        tabIndex="-1"
        aria-labelledby="profileAvatarCropModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog mx-auto"
          style={{ width: "80%", maxWidth: 700 }}
        >
          <div className="modal-content container">
            <div className="modal-header">
              <h5 className="modal-title" id="profileAvatarCropModalLabel">
                Crop Profile Picture
              </h5>
              <button
                type="button"
                className="btn background-none p-0 position-absolute"
                style={{ right: 16 }}
                onClick={() => closeModal("profile-avatar-crop-modal")}
                aria-label="Close"
              >
                <RiCloseFill />
              </button>
            </div>
            <div className="modal-body pt-0">
              {srcImage && (
                <ReactCrop
                  src={srcImage}
                  crop={crop}
                  onChange={(newCrop) => setCrop(newCrop)}
                  onComplete={handleCropComplete}
                  aspect={1}
                >
                  <img
                    ref={imgRef}
                    src={srcImage}
                    alt="Crop me"
                    style={{ maxWidth: "100%" }}
                  />
                </ReactCrop>
              )}
            </div>
            <div className="modal-footer px-0 py-2">
              <button
                type="button"
                className="d-flex align-items-center gap-1 btn btn-sm btn-danger"
                onClick={() => closeModal("profile-avatar-crop-modal")}
              >
                Close
              </button>
              <button
                type="button"
                className="d-flex align-items-center gap-1 btn btn-sm btn-primary"
                onClick={handleCropConfirm}
                disabled={loading}
              >
                {loading ? <Loader /> : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileAvatar;
