import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getlatestDesigner } from "../../../api/Api";
import { Link } from "react-router-dom";

function FeaturedDesigner() {
  const [designerRows, setDesignerRows] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDesigners = async () => {
      setLoading(true);
      const res = await getlatestDesigner();

      if (res.status === "success") {
        setDesignerRows(res.data);
      }
      setLoading(false);
    };

    fetchDesigners();
    // eslint-disable-next-line
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 5000,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 5000,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 5000,
          autoplaySpeed: 5000,
        },
      },
    ],
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center my-5">
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <>
      <div className="container my-5">
        <div className="text-center mb-5">
          <h1>Designers</h1>
        </div>
      </div>
      {designerRows.length > 0 ? (
        <Slider {...settings} className="continuous-slider">
          {designerRows.map((portfolio, index) => (
            <div key={index} className="card border-0 slider-card">
              <Link to={`/designer-profile/${portfolio._id}`}>
                <div
                  className="rounded-4 bg-img-feat-design d-flex align-items-end"
                  style={{
                    backgroundImage: `url(${
                      portfolio?.profileImg === null ||
                      portfolio?.profileImg === ""
                        ? "/assets/images/header-avatar.png"
                        : portfolio?.profileImg
                    })`,
                  }}
                >
                  <div className="container">
                    <div className="mb-3 d-flex flex-column">
                      {portfolio?.title && (
                        <span className="fw-bold hover-slides text-white">
                          {portfolio?.title}
                        </span>
                      )}
                      {portfolio?.name && (
                        <span className="text-white my-1 hover-slides  fw-semibold">
                          {portfolio?.name}
                        </span>
                      )}
                      {portfolio?.defineYourself && (
                        <span className="text-white hover-slides">
                          {portfolio?.defineYourself}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="text-center my-5">
          <h4>No Featured Designer Available</h4>
        </div>
      )}
    </>
  );
}

export default FeaturedDesigner;
