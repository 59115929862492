import React, { useState } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { saveProject } from "../../../api/Api";
import { IoShareOutline } from "react-icons/io5";
import moment from "moment";
import ImageGalleryWithSlider from "./ImageGalleryWithSlider";
import Commnets from "./Commnets";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaFacebook, FaRegCopy, FaTwitter } from "react-icons/fa";

function ImageModal({ portfolio, designer, setActiveTab }) {
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const { token: userToken } = useSelector((state) => state.userData);

  const handleSave = async () => {
    setLoading(true);
    try {
      const result = await saveProject(
        { portfolioId: portfolio?._id },
        userToken
      );
      if (result.status === "success") {
        toast.success(result.message || "Saved Successfully!");
      } else {
        toast.error(result.message || "Failed to Save.");
      }
    } catch (error) {
      toast.error("An error occurred during status update. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  function handleTab() {
    setActiveTab("contact");
  }

  const designerProfileUrl = `https://www.iamadesigner.com/designer-profile/${designer?._id}`;

  const shareOnFacebook = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    designerProfileUrl
  )}`;
  const shareOnTwitter = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    designerProfileUrl
  )}&text=Check%20out%20this%20designer!`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(designerProfileUrl);
    toast.success("Link copied to clipboard!");
  };

  return (
    <div
      className="modal fade"
      id={`image-modal-${portfolio?._id}`}
      tabIndex="-1"
      aria-labelledby="image-modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row m-0">
              <div className="col-md-7 p-0">
                <div className="h-100">
                  <ImageGalleryWithSlider images={portfolio?.images} />
                </div>
              </div>
              <div className="col-md-5 mt-5 mt-md-0">
                <div className="d-flex justify-content-between">
                  <div className="fs-3">
                    <Popup
                      trigger={
                        <button
                          className="btn"
                          style={{
                            cursor: "pointer",
                            zIndex: 10000,
                          }}
                        >
                          <IoShareOutline size={20} />
                        </button>
                      }
                      position="bottom center"
                      closeOnDocumentClick
                      arrow={false}
                      contentStyle={{
                        zIndex: 10001,
                      }}
                      on="click"
                    >
                      <div className="popup- d-flex justify-content-around">
                        <a
                          href={shareOnFacebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="nav-color"
                        >
                          <FaFacebook />
                        </a>

                        <a
                          href={shareOnTwitter}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="nav-color"
                        >
                          <FaTwitter />
                        </a>

                        <span
                          onClick={copyToClipboard}
                          className="nav-color"
                          style={{ cursor: "pointer" }}
                        >
                          <FaRegCopy />
                        </span>
                      </div>
                    </Popup>
                  </div>
                  <div className="d-flex align-items-center">
                    {userToken ? (
                      <button
                        type="submit"
                        className="btn rounded py-1 custom-btn text-white"
                        onClick={handleSave}
                        disabled={loading}
                      >
                        <small>Add to Collection</small>
                      </button>
                    ) : (
                      <Link
                        to="/user/login"
                        className="btn rounded py-1 custom-btn text-white"
                      >
                        <small data-bs-dismiss="modal" aria-label="Close">
                          Login as User to Save Project
                        </small>
                      </Link>
                    )}
                  </div>
                </div>
                <h4 className="mt-5">{portfolio?.title}</h4>
                <div
                  className="description-container"
                  style={{
                    display: !isExpanded && "-webkit-box",
                    WebkitLineClamp: !isExpanded && 2,
                    WebkitBoxOrient: !isExpanded && "vertical",
                    overflow: !isExpanded && "hidden",
                    textOverflow: !isExpanded && "ellipsis",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: portfolio?.description,
                  }}
                ></div>
                {
                  <button
                    className="btn btn-link p-0 m-0 fs-14"
                    style={{ color: "#927D34" }}
                    onClick={toggleDescription}
                  >
                    {isExpanded ? "Read less" : "Read more"}
                  </button>
                }
                <div className="d-flex justify-content-between mt-5">
                  <div className="d-flex align-items-center">
                    <img
                      className="rounded-5 mt-1 img-responsive"
                      width={45}
                      height={45}
                      src={
                        portfolio?.designer?.profileImg ||
                        designer?.profileImg ||
                        "/assets/images/header-avatar.png"
                      }
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                    <div className="ms-2">
                      <div style={{ fontSize: "16px" }}>
                        {portfolio?.designer?.name || designer?.name} |{" "}
                        {portfolio?.designer?.defineYourself ||
                          designer?.defineYourself}
                      </div>
                      <div style={{ fontSize: "12px" }}>
                        {`${moment(
                          portfolio?.designer?.createdAt || designer?.createdAt
                        ).format("DD MMMM YYYY")}`}
                      </div>
                    </div>
                  </div>
                  <button
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="btn px-3 text-white custom-btn"
                    onClick={handleTab}
                  >
                    Connect
                  </button>
                </div>
                <Commnets token={userToken} id={portfolio?._id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageModal;
