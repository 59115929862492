import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { IoClose, IoCloseCircle, IoFilterOutline } from "react-icons/io5";
import Cards from "./Cards";
import ModelLeft from "./ModelLeft";
import { getDesignersBySearchTerm } from "../../api/Api";
import { RiCheckLine } from "react-icons/ri";

function filterDesigners(designers, categories, services, skills) {
  return designers.filter((designer) => {
    const categoryMatch =
      categories.length === 0 ||
      categories.some((cat) =>
        designer.designerCategory.includes(
          cat.replace(/I am an?/, "").replace(/designer/i, "")
        )
      );
    const serviceMatch =
      services.length === 0 ||
      services.some((service) => designer.services.includes(service));
    const skillMatch =
      skills.length === 0 ||
      skills.some((skill) =>
        designer.skills.some((designerSkill) => designerSkill.name === skill)
      );
    return categoryMatch && serviceMatch && skillMatch;
  });
}

function Main() {
  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState(
    "Searched designers appear here."
  );
  const [searchedDesigners, setSearchedDesigners] = useState([]);
  const [totalDesigners, setTotalDesigners] = useState(0);
  const [initialDesigners, setInitialDesigners] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortCriteria, setSortCriteria] = useState("nameAsc");

  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [skills, setSkills] = useState([]);

  const searchTermParam = searchParams.get("searchTerm");

  useEffect(() => {
    if (!searchTermParam) {
      fetchInitialData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchTermParam) {
      fetchSearchedData();
    }
    // eslint-disable-next-line
  }, [searchTermParam]);

  useEffect(() => {
    if (searchTermParam && page > 1) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [page]);

  const fetchInitialData = async () => {
    const controller = new AbortController();
    setSearchedDesigners([]);
    setLoading(true);
    try {
      const result = await getDesignersBySearchTerm(controller);
      if (result?.status === "success") {
        setInitialDesigners(result.data);
      }
      handleSort(sortCriteria);
    } finally {
      setLoading(false);
    }
    return () => controller.abort("Aborted Intentionally");
  };

  const fetchSearchedData = async () => {
    const controller = new AbortController();
    setInitialDesigners([]);
    setLoading(true);
    try {
      const result = await getDesignersBySearchTerm(
        { searchTerm: searchTermParam, page: 1 },
        controller
      );
      if (result?.status === "success") {
        setSearchedDesigners(result.data);
        setTotalDesigners(result.totalDesigners);
      } else {
        setHelperText(result?.message || "No results found.");
      }
    } finally {
      setLoading(false);
    }
    return () => controller.abort("Aborted Intentionally");
  };

  const fetchNextPage = async () => {
    setLoading(true);
    try {
      const result = await getDesignersBySearchTerm({
        searchTerm: searchTermParam,
        page,
      });
      if (result?.status === "success") {
        setSearchedDesigners((prev) => [...prev, ...result.data]);
      } else {
        setHelperText(result?.message || "No more results found.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      setSearchParams({ searchTerm });
    }
  };

  const filteredSearchedDesigners = filterDesigners(
    searchedDesigners,
    categories,
    services,
    skills
  );
  const filteredInitialDesigners = filterDesigners(
    initialDesigners,
    categories,
    services,
    skills
  );

  const renderDesignerList = () => {
    if (
      loading &&
      filteredSearchedDesigners.length === 0 &&
      filteredInitialDesigners.length === 0
    ) {
      return <p>Loading...</p>;
    }

    if (filteredSearchedDesigners.length > 0) {
      return (
        <InfiniteScroll
          dataLength={filteredSearchedDesigners.length}
          next={() => !loading && setPage((prev) => prev + 1)}
          hasMore={searchedDesigners.length !== totalDesigners}
          loader={<p>Loading...</p>}
          scrollableTarget="user-panel-main"
          className="row"
        >
          {filteredSearchedDesigners.map((designer, i) => (
            <Cards key={i} designer={designer} />
          ))}
        </InfiniteScroll>
      );
    } else if (filteredInitialDesigners.length > 0) {
      return (
        <div className="row">
          {filteredInitialDesigners.map((designer, i, arr) => {
            if (i > 7) return null;
            return <Cards key={i} designer={designer} />;
          })}
        </div>
      );
    } else {
      return <p className="d-flex justify-content-center">{helperText}</p>;
    }
  };

  const handleSort = (criteria) => {
    setSortCriteria(criteria);
    let sortedDesigners;

    if (filteredSearchedDesigners.length > 0) {
      sortedDesigners = [...searchedDesigners];
    } else if (filteredInitialDesigners.length > 0) {
      sortedDesigners = [...initialDesigners];
    } else {
      return; // No designers to sort
    }

    sortedDesigners.sort((a, b) => {
      switch (criteria) {
        case "nameAsc":
          return a.name.localeCompare(b.name);
        case "nameDesc":
          return b.name.localeCompare(a.name);
        case "createdAtAsc":
          return new Date(a.createdAt) - new Date(b.createdAt);
        case "createdAtDesc":
          return new Date(b.createdAt) - new Date(a.createdAt);
        default:
          return 0;
      }
    });

    if (filteredSearchedDesigners.length > 0) {
      setSearchedDesigners(sortedDesigners);
    } else if (filteredInitialDesigners.length > 0) {
      setInitialDesigners(sortedDesigners);
    }
  };

  return (
    <div className="container pt-5">
      <div className="text-center my-5 pt-5 pb-3 display-5">
        Search Your Dream Designer
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-lg-3 d-flex justify-content-end order-1 order-lg-0 mt-3 mt-lg-0">
            {!(
              categories.length === 0 &&
              services.length === 0 &&
              skills.length === 0
            ) && (
              <button
                type="button"
                className="btn px-3 py-2 rounded-5 gap-2 me-2 me-lg-auto"
                style={{
                  minWidth: "fit-content",
                  background: "#927D34",
                  color: "white",
                }}
                onClick={() => {
                  setCategories([]);
                  setServices([]);
                  setSkills([]);
                }}
              >
                <IoClose /> Clear Filters
              </button>
            )}
            <button
              type="button"
              className="btn btn-dark px-3 py-2 rounded-5 gap-2 me-2 me-lg-auto"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              style={{ minWidth: "fit-content" }}
            >
              <IoFilterOutline /> Filter
            </button>
          </div>
          <div className="col-12 col-lg-9 search order-0 order-lg-1">
            <input
              type="text"
              className="search__input"
              placeholder="Type your text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTermParam && (
              <button
                type="button"
                className="btn p-0 rounded-pill d-flex align-items-center justify-content-center me-2"
                style={{
                  background: "#927D34",
                  width: "20px",
                  height: "20px",
                  aspectRatio: "1/1",
                }}
                onClick={() => {
                  setSearchParams({});
                  fetchInitialData();
                }}
              >
                <IoCloseCircle size={20} color={"white"} />
              </button>
            )}
            <button
              type="submit"
              className="btn btn-lg btn-dark px-5 rounded-5"
            >
              Search
            </button>
          </div>
        </div>
      </form>

      <div className="container">
        <div className="d-flex justify-content-between align-items-center mt-5 p-3">
          <h6 className="fw-bold mb-0">Search Results</h6>
          <div className="dropdown">
            <button
              className="btn btn-outline-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Sort by
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton"
            >
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSort("nameAsc")}
                >
                  {sortCriteria === "nameAsc" && <RiCheckLine />} Name (A-Z)
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSort("nameDesc")}
                >
                  {sortCriteria === "nameDesc" && <RiCheckLine />} Name (Z-A)
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSort("createdAtAsc")}
                >
                  {sortCriteria === "createdAtAsc" && <RiCheckLine />} Date
                  Created (Oldest first)
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSort("createdAtDesc")}
                >
                  {sortCriteria === "createdAtDesc" && <RiCheckLine />} Date
                  Created (Newest first)
                </button>
              </li>
            </ul>
          </div>
        </div>
        {renderDesignerList()}
      </div>
      <ModelLeft
        categoriesToFilter={categories}
        setCategoriesToFilter={setCategories}
        servicesToFilter={services}
        setServicesToFilter={setServices}
        skillsToFilter={skills}
        setSkillsToFilter={setSkills}
      />
    </div>
  );
}

export default Main;
