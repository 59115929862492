import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  setDesignerToken,
  setDesigner,
} from "../../redux/actions/designerActions";
import { login, getDesignerData, getUserData } from "../../api/Api";
import { setUser, setUserToken } from "../../redux/actions/userActions";
import { setIsTokenInitialized } from "../../redux/actions/generalStateActions";

function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const result = await login({ email, password });

      if (result.status === "success") {
        switch (result.role) {
          case "designer":
            try {
              const designerData = await getDesignerData(result.data);
              dispatch(setDesigner(designerData.data));
              dispatch(setIsTokenInitialized(true));
              dispatch(setDesignerToken(result.data));
              toast.success("Login Successful!");
              setEmail("");
              setPassword("");
              navigate("/designer/dashboard");
            } catch (error) {
              toast.error(
                "An error occurred during registration. Please try again."
              );
            }
            break;

          case "user":
            try {
              const userData = await getUserData(result.data);
              dispatch(setUser(userData.data));
              dispatch(setIsTokenInitialized(true));
              dispatch(setUserToken(result.data));
              toast.success("Login Successful!");
              setEmail("");
              setPassword("");
              navigate("/user/dashboard");
            } catch (error) {
              toast.error(
                "An error occurred during registration. Please try again."
              );
            }
            break;
          default:
            toast.error("Invalid user type selected.");
        }
      } else {
        toast.error(
          result.message || "An error occurred during login. Please try again."
        );
      }
    } catch (error) {
      toast.error("An error occurred during login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=" d-flex justify-content-center align-items-center pt-3 mt-5">
      <div className="text-center mt-5 designer-login-card overflow-hidden">
        <div className="row">
          <div className="col-md-6 d-none d-md-block">
            <img
              className="w-100 h-100 object-fit-cover "
              src="/assets/images/login-screen.jpg"
              alt="Designer Login"
            />
          </div>
          <div className="col-md-6 my-auto">
            <img
              className="pt-2"
              width={180}
              src="/assets/images/authlogo.png"
              alt="logo"
            />
            <div className="fs-2">Login</div>

            <div className="mx-4">
              <small className="mb-3">
                Login to access special features for designers and to share your
                designs with the world.
              </small>
              <form onSubmit={handleLogin}>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    value={email}
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="floatingInput">Email address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    value={password}
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="floatingPassword">Password</label>
                </div>
                <button
                  type="submit"
                  className="btn rounded-5 w-100 py-2 custom-btn text-white mb-3"
                  disabled={loading}
                >
                  {loading ? "LOADING..." : "LOGIN"}
                </button>
              </form>
              <div className="mb-4">
                <Link to={"/forgetpassword"}>Forget Password?</Link>
              </div>
              <div className="pb-3">
                <small className="text-muted ">
                  Don't have an account?
                  <Link
                    to={"/register"}
                    className="text-decoration-underline text-muted"
                  >
                    {" "}
                    Register Now
                  </Link>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;
