import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { updateUserData } from "../../api/Api";
import { setUser } from "../../redux/actions/userActions";
import { toast } from "react-toastify";
import ProfileAvatar from "../../components/common/ProfileAvatar";

function UserProfileSettings() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userData.user);
  const { token: userToken } = useSelector((state) => state.userData);

  const [userData, setUserData] = useState({
    name: user.name ?? "",
    email: user.email ?? "",
    city: user.city ?? "",
    country: user.country ?? "",
    occupation: user.occupation ?? "",
    profileImg: user.profileImg ?? "/assets/images/header-avatar.png",
  });

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    setUserData((oldUserData) => ({
      ...oldUserData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await updateUserData(userData, userToken);

    if (res.status === "success") {
      dispatch(setUser(res.data));
      toast.success("Profile Updated Successfully!");
    } else {
      toast.error(res.errors[0].msg ?? res.message);
    }
  };

  return (
    <section className="dashboard-section user-profile-settings">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          PROFILE SETTINGS
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/user/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">Profile</li>
        </ol>
      </div>
      <div className="section-spacing">
        <div className="card mt-n5">
          <div className="card-body p-4">
            <div className="text-center">
              <ProfileAvatar
                data={userData}
                setData={setUserData}
                token={userToken}
              />
              <h5 className="fs-16 mb-1">{user.name}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="section-spacing">
        <div className="card mt-xxl-n5">
          <div className="card-header">
            <h5 className="card-title mb-0">Profile Details</h5>
          </div>
          <div className="card-body p-4">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Full Name
                    </label>
                    <input
                      id="name"
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={handleInputChange}
                      value={user.name}
                      required
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      onChange={handleInputChange}
                      value={user.email}
                      required
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Occupation
                    </label>
                    <input
                      id="occupation"
                      type="text"
                      className="form-control"
                      name="occupation"
                      onChange={handleInputChange}
                      value={user.occupation}
                      required
                      placeholder="Occupation"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input
                      id="city"
                      type="text"
                      className="form-control"
                      name="city"
                      placeholder="City"
                      onChange={handleInputChange}
                      value={user.city}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      name="country"
                      onChange={handleInputChange}
                      value={user.country}
                      placeholder="Country"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserProfileSettings;
