import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { RiCloseFill, RiPlayFill } from "react-icons/ri";
import { singleImageUpload, updateDesignerData } from "../../api/Api";
import Loader from "./Loader";
import { setDesigner } from "../../redux/actions/designerActions";

function SingleFileWithDisplay({
  inputName,
  labelJsx,
  accept,
  allowedTypes,
  clientData,
  setClientData,
  token,
  ...props
}) {
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState();
  const [deleteLoading, setDeleteLoading] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setFile(clientData[inputName] || "");
    // eslint-disable-next-line
  }, [clientData]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      const errorMessage =
        inputName === "resume"
          ? "Invalid file type. Please upload an image or PDF."
          : "Invalid file type. Please upload a video.";
      toast.error(errorMessage);
      return;
    }

    if (file.length >= 5) {
      toast.error(`You can upload max ${5} Pictures`);
      return;
    }

    setLoading(true);
    const formdata = new FormData();
    formdata.append("image", file);

    const result = await singleImageUpload(formdata);
    if (result.status === "success") {
      const fileUrl = result.data;
      updateClientProfile(fileUrl);
    } else {
      toast.error("Fail To Upload Image");
    }
    setLoading(false);
  };

  const updateClientProfile = async (fileUrl) => {
    const result = await updateDesignerData({ [inputName]: fileUrl }, token);
    if (result.status === "success") {
      toast.success("Media Gallery Updated Successfully");
      dispatch(setDesigner(result.data));
      setFile(fileUrl);
      setClientData((oldClientData) => ({
        ...oldClientData,
        [inputName]: fileUrl,
      }));
    } else {
      toast.error(result.message);
    }
    setDeleteLoading(false);
  };

  const handleDeleteImage = async () => {
    setDeleteLoading(true);
    updateClientProfile("");
  };

  return (
    <div className={`flex-grow-1 mb-3 ${props.className}`}>
      <div className="mb-3">
        <label htmlFor={inputName} className="form-label">
          {labelJsx}
        </label>
        <input
          type="file"
          id={inputName}
          name={inputName}
          className="form-control fs-14"
          accept={accept ? `${accept}/*` : "*"}
          onChange={handleFileChange}
          disabled={loading}
        />
      </div>
      <div
        className="mt-3 p-2 rounded-3 d-flex gap-3 flex-wrap"
        style={{ backgroundColor: "#fbf7f4", width: "fit-content" }}
      >
        <div
          className={`position-relative avatar-xl ${
            !file && "d-flex align-items-center justify-content-center"
          }`}
        >
          {file ? (
            <div className="overflow-hidden rounded-3 h-100">
              {accept === "video" ? (
                <a href={file} target="_blank" rel="noreferrer noopener">
                  <img
                    src={
                      file?.replace ? file?.replace(/\.[^/.]+$/, ".png") : file
                    }
                    alt={`Gallery 1`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </a>
              ) : (
                <img
                  src={
                    file?.replace ? file?.replace(/\.[^/.]+$/, ".png") : file
                  }
                  alt={`Gallery 1`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
              <button
                type="button"
                className="btn btn-light d-flex align-items-center p-0 position-absolute"
                onClick={() => handleDeleteImage()}
                style={{
                  aspectRatio: "1 / 1",
                  top: 5,
                  right: 5,
                }}
                disabled={deleteLoading}
              >
                {deleteLoading ? (
                  <Loader size={12} color="black" />
                ) : (
                  <RiCloseFill size={14} />
                )}
              </button>
              {[".mp4", ".webm", ".ogg", ".avi", ".mov"].some((format) =>
                file.endsWith(format)
              ) && (
                <div
                  className="position-absolute"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    pointerEvents: "none",
                  }}
                >
                  <RiPlayFill size={32} color="white" />
                </div>
              )}
            </div>
          ) : (
            <p className="form-label fs-12">No file chosen.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleFileWithDisplay;
