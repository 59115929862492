import React from "react";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="container">
      <div className="mt-5 pt-3">
        <div className="d-flex justify-content-center mt-5">
          <img
            className="about-img mb-5"
            src="assets/images/about.jpg"
            width={"50%"}
            alt="About Us"
          />
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <div className="prose w-75 mb-2">
          <p>
            Welcome to{" "}
            <Link to={"/"} className="fw-semibold">
              <span className="text-nowrap">I AM A DESIGNER</span>
            </Link>
            – your new creative home! We believe that every designer has a story
            to tell, and we’ve built a space where that story can truly shine.
          </p>
          <ul>
            <li>Jewelry designer</li>
            <li>Fashion designer</li>
            <li>Shoes designer</li>
            <li>Or anything in between</li>
          </ul>
          <p>
            <Link to={"/"} className="fw-semibold">
              <span className="text-nowrap">I AM A DESIGNER</span>
            </Link>{" "}
            is the perfect platform for showcasing your work, sharing your
            journey, and connecting with brands and employers looking for
            creative talent just like you.
          </p>

          <p>
            At{" "}
            <Link to={"/"} className="fw-semibold">
              <span className="text-nowrap">I AM A DESIGNER</span>
            </Link>
            , we know that design is more than just a job – it’s a way of
            thinking, a form of expression, and a big part of who you are.
            That’s why we’ve made it easy for you to create a profile that’s
            uniquely yours. Showcase not just your portfolio but also your
            personal story and professional journey.
          </p>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <div className="prose w-75 mb-2">
          <h5 className="fw-semibold">For Designers</h5>
          <ul>
            <li>Build a sleek profile that shows off your best work.</li>
            <li>Highlight your experience and tell your authentic story.</li>
            <li>Upload your resume and share your latest projects.</li>
            <li>Easily update your profile to keep it fresh and engaging.</li>
          </ul>
          <p>
            Whether you’re a seasoned pro or just starting out,{" "}
            <Link to={"/"} className="fw-semibold">
              <span className="text-nowrap">I AM A DESIGNER</span>
            </Link>{" "}
            is your platform to showcase your creativity and connect with
            opportunities in the design world.
          </p>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <div className="prose w-75 mb-2">
          <h5 className="fw-semibold">For Employers</h5>
          <p>
            We know finding the right designer can feel like searching for a
            needle in a haystack. At{" "}
            <Link to={"/"} className="fw-semibold">
              <span className="text-nowrap">I AM A DESIGNER</span>
            </Link>
            , we simplify the process.
          </p>
          <ul>
            <li>Discover a community of talented, creative designers.</li>
            <li>Find freelancers or long-term hires.</li>
            <li>
              Advanced search features to find the perfect fit for your brand.
            </li>
            <li>
              Streamlined process to connect with designers quickly and
              stress-free.
            </li>
          </ul>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <div className="prose w-75 mb-2">
          <h5 className="fw-semibold">Why Us?</h5>
          <p>
            At{" "}
            <Link to={"/"} className="fw-semibold">
              <span className="text-nowrap">I AM A DESIGNER</span>
            </Link>
            , we’re passionate about design and even more passionate about
            connecting designers and companies.
          </p>
          <ul>
            <li>Built around simplicity, efficiency, and creativity.</li>
            <li>Designed with both designers and employers in mind.</li>
            <li>Intuitive platform for seamless experience.</li>
          </ul>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <div className="prose w-75 mb-2">
          <h5 className="fw-semibold">Our Vision</h5>
          <p>
            We believe that design has the power to transform the world. Our
            vision is to create a vibrant community where designers and
            employers collaborate, innovate, and make amazing things happen.
          </p>
          <ul>
            <li>Inspire creativity and spark new ideas.</li>
            <li>
              Help designers of all kinds connect with the opportunities they
              deserve.
            </li>
            <li>
              A community where creativity thrives, and connections are made.
            </li>
          </ul>
          <p>
            Welcome to{" "}
            <Link to={"/"} className="fw-semibold">
              <span className="text-nowrap">I AM A DESIGNER</span>
            </Link>
            , where creativity meets opportunity.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Main;
